<template>
  <div class="container">
    <el-tabs tab-position="left" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in gasTypeList" :key="index" :name="item.dictValue">
        <span slot="label"><i :class="iconList[index]"></i> {{item.dictLabel}}</span>
        <gas-card v-for="(item,index) in gunsList" :key="index" :bodyData="item" class="tabs-card" @editOilPrice="editOilPrice"></gas-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { gasType, getGuns, updateGoodsPrice } from "@/api/gasSet"
import GasCard from '@/components/common/gasCard.vue'
import editGasPrice from './editGasPrice.vue'
export default {
  name: "GasSet",
  components: { GasCard },
  data() {
    return {
      gasTypeList: [],
      iconList: ['iconfont icon-jiayouqiang', 'iconfont icon-meiqitianranqi', 'iconfont icon-map-oil', 'iconfont icon-dianchibao'],
      activeName: "1",
      gunsList: []
    };
  },
  created() {
    this.getGasType()
    this.getGunsList("1")
  },
  methods: {
    // 获取油类型
    getGasType() {
      gasType().then((res) => {
        this.gasTypeList = res.data
      })
    },
    // 获取油枪列表
    getGunsList(dictValue) {
      getGuns({ type: dictValue }).then((res) => {
        this.gunsList = res.data
      })
    },
    // 标签切换
    handleClick(tab, event) {
      this.getGunsList(tab.name)
    },
    // 编辑油价
    editOilPrice(param) {
      this.$layer.closeAll()
      this.$layer.iframe({
        content: {
          content: editGasPrice,
          parent: this,
          data: { iframeData: param }
        },
        type: 2,
        offset: 'auto',
        area: ['350px', '220px'],
        // title: '修改油枪价格',  
        title: `修改${param.gunNum}号/${param.goodsName}价格`,
        maxmin: false,
        shade: false,
        shadeClose: false,
        scrollbar: true,
        resize: false,
        tipsMore: false,
        cancel: () => {
          this.getGunsList(param.type)
        }
      })

    },
    // 保存油价
    saveOilPrice(param) {
      updateGoodsPrice(param).then((res) => {
        this.$message.success('修改油枪价格成功')
        this.getGunsList(param.type)
      })
    }

  },
}
</script>

<style scoped>
.el-tabs__content {
  height: 100%;
}
.tabs-card {
  float: left;
  margin: 10px;
}
</style>