import request from '@/utils/request'

// 获取油类型
export function gasType() {
	return request({
		url: '/api/boss/gas/type',
		method: 'get'
	})
}

// 获取油枪列表
export function getGuns(query) {
	return request({
		url: '/api/boss/gas/getGuns',
		method: 'get',
		params: query
	})
}

// 修改油枪价格
export function updateGoodsPrice(data) {
	return request({
		url: '/api/boss/gas/updateGoodsPrice',
		method: 'post',
		data
	})
}
