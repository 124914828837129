<template>
  <div class="gas-card-body divflexya">
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-form-item label="国标价(¥)" prop="oldHyPrice">
        <el-input width="200px" type="text" v-model="form.oldMarketPrice" oninput="value=value.replace(/[^0-9.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
      </el-form-item>
      <el-form-item label="油枪价(¥)" prop="oldMarketPrice">
        <el-input width="200px" type="text" v-model="form.oldHyPrice" />
      </el-form-item>
      <div class="btn-box divflexxa">
        <el-button type="primary" size="mini" @click="editPrice">保存</el-button>
        <el-button type="primary" size="mini" @click="close">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    layerid: {
      type: String,
      default: ''
    },
    iframeData: {
      Object,
      default: {}
    },
  },
  created() {
    this.form.oldHyPrice = this.iframeData.hyPrice / 100
    this.form.oldMarketPrice = this.iframeData.marketPrice / 100
    this.form.goodsId = this.iframeData.goodsId
    this.form.type = this.iframeData.type
  },
  data() {
    return {
      form: {
        oldHyPrice: undefined,
        oldMarketPrice: undefined,
        goodsId: undefined,
        type: undefined,
      },
      rules: {
        oldHyPrice: [{ required: true, message: '请输入国标价格', trigger: 'blur' }],
        oldMarketPrice: [{ required: true, message: '请输入油枪价格', trigger: 'blur' }]
      }
    };
  },
  methods: {
    editPrice() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.hyPrice = Number((this.form.oldHyPrice * 100).toFixed(0))
          this.form.marketPrice = Number((this.form.oldMarketPrice * 100).toFixed(0))
          this.form.goodsId = this.form.goodsId
          this.$layer.close(this.layerid);
          this.$parent.saveOilPrice(this.form);
        }
      });
    },
    close() {
      this.$layer.close(this.layerid);
    }
  },

}
</script>
<style lang="scss" scoped>
.gas-card-body {
  margin-top: 15px;
  width: 100%;
  .el-input {
    width: 200px;
  }
}
.btn-box{
  width: 100%;
}
</style>
