<template>
  <el-card class="gas-card-item divflexyb" shadow="always" body-style="{ padding: '5px' }">
    <div class="gas-card-title divflexxb">
      <div class="divflexxb">
        <div style="font-size:25px"><b>{{bodyData.gunNum}}号</b>
        </div>/{{bodyData.goodsName}}
      </div>
      <img src="@/assets/images/logo.png" alt="" class="logo">
    </div>
    <div class="gas-card-body">
      <el-form ref="form" :model="bodyData" label-width="60px" inline>
        <el-form-item label="国标价">
          <div class="price-box">{{bodyData.marketPrice|Money}}</div>
        </el-form-item>
        <el-form-item label="油枪价">
          <div class="price-box"> {{bodyData.hyPrice|Money}} </div>
        </el-form-item>
        <el-button type="primary" size="mini" @click="editPrice">编辑</el-button>
      </el-form>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "GasCard",
  data() {
    return {
      inputDisabled: true
    };
  },
  props: {
    bodyData: { Object, default: {} },
  },
  methods: {
    editPrice() {
      this.$emit('editOilPrice', this.bodyData);
    },
  },
}
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 10px;
}
.gas-card-item {
  width: 400px;
  height: 120px;
  margin-bottom: 10px;
}

.gas-card-title {
  width: 100%;
  height: 40px;
  color: #56585c;
  font-size: 20px;
}

.gas-card-body {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .price-box {
    width: 80px;
    background-color: #e9e0e0;
    border: 1px solid #e9e0e0;
    border-radius: 5px;
    text-align: center;
  }
}
.logo {
  height: 40px;
}
</style>